var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.properties.filed_content != 'Hide'),expression:"data.properties.filed_content != 'Hide'"}],style:(_vm.getElementStyle)},[((_vm.fromChildTable || _vm.fromDataTable) && _vm.data.allow_quick_add)?_c('el-row',[_c('el-col',{attrs:{"span":2,"offset":_vm.fromChildTable ? 22 : 19}},[_c('el-tooltip',{attrs:{"effect":"dark","content":'Add new ' + _vm.data.label,"placement":"left"}},[_c('quickEntityAddWidget',{attrs:{"currentEntity":_vm.currentEntity,"parentForm":_vm.form,"parentFields":_vm.fieldsData,"fromRepeatable":_vm.fromRepeatable},on:{"onNewEntity":_vm.setQuickAddEntityDataAsValue}})],1)],1)],1):_vm._e(),(!_vm.isDefalutPosq)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"display":"flex"}},[(
            _vm.hasLabel &&
            _vm.data.properties.filed_content != 'Hide' &&
            !_vm.data.detailed_view
          )?_c('label',{staticStyle:{"margin-right":"5px","white-space":"nowrap"},style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.data.detailed_view)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v("*")]):_vm._e(),_c('span',{staticStyle:{"margin-right":"5px"}},[(_vm.data.description && !_vm.fromRepeatable && _vm.hasLabel)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1),(
            _vm.data.allow_quick_add &&
            !_vm.isViewCopy &&
            !_vm.fromRepeatable &&
            (_vm.isFromDocument || !_vm.checkReadonly())
          )?_c('span',{staticStyle:{"margin-right":"5px"}},[_c('quickEntityAddWidget',{attrs:{"currentEntity":_vm.currentEntity,"parentForm":_vm.form,"parentFields":_vm.fieldsData},on:{"onNewEntity":_vm.setQuickAddEntityDataAsValue}})],1):_vm._e()]),_c('div',[_c('el-col',{attrs:{"span":_vm.showLabel && _vm.isDefalutPosq ? (_vm.data.allow_quick_add ? 24 : 23) : 23}},[(
              !_vm.isViewCopy &&
              _vm.data.properties.filed_content != 'Hide' &&
              !_vm.refresh
            )?_c('div',{style:(_vm.getStyle)},[(_vm.data.detailed_view)?_c('div',[(_vm.data.detailed_view)?_c('el-button',{attrs:{"type":"text","underline":false,"disabled":_vm.checkReadonly(),"loading":_vm.optionsLoading},on:{"click":_vm.openDetailedSelect}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
                  _vm.form[_vm.data.key] && _vm.form[_vm.data.key] && _vm.form[_vm.data.key + '/name']
                )?_c('div',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key + "/name"])+" ")]):_vm._e()],1):_c('el-row',[_c('el-col',{attrs:{"span":_vm.data.properties.qr_code_scanning ? 22 : 24}},[_c('el-select',{staticClass:"centered-text-input",staticStyle:{"width":"100%","display":"flex","align-items":"center","flex-wrap":"nowrap"},attrs:{"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"placeholder":_vm.data.placeholder || _vm.data.label,"clearable":"","collapse-tags":"","value-key":'entity_data_id',"filterable":"","suffix-icon":_vm.data.properties.el_icon,"loading":_vm.optionsLoading,"remote-method":_vm.searchEntityData,"remote":"","loading-text":"Loading...","multiple":_vm.data.allow_multiple || false},on:{"change":function($event){return _vm.checkEntityFields(_vm.form[_vm.data.key], true)},"clear":_vm.handleClear},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[(!_vm.data.is_entity_field)?[(_vm.data.show_all_option)?_c('el-option',{attrs:{"value":"ALL","label":"All"}}):_vm._e(),_vm._l((_vm.entitiesData),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}})}),(!_vm.isSearching && _vm.hasMore)?_c('el-option',{attrs:{"value":"LOADMORE","label":"Load more..."}}):_vm._e()]:_vm._e(),(
                      _vm.data.is_entity_field &&
                      _vm.form[_vm.data.key + '/default'] &&
                      _vm.form[_vm.data.key + '/default'].length
                    )?_vm._l((_vm.entitiesData.filter(
                        (e) =>
                          _vm.form[_vm.data.key + '/default'].indexOf(e._id) != -1
                      )),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}})}):_vm._e()],2)],1),(_vm.data.properties.qr_code_scanning && !_vm.isView)?_c('el-col',{attrs:{"span":_vm.data.properties.qr_code_scanning ? 2 : 24}},[_c('span',{staticClass:"qrCodeBlock"},[(_vm.data.properties.qr_code_scanning)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.qrDescription}},[_c('i',{staticClass:"el-icon-camera size-2em",attrs:{"slot":"reference"},on:{"click":_vm.enableQRReader},slot:"reference"})]):_vm._e()],1)]):_vm._e()],1),(_vm.showWarningText)?_c('span',{staticClass:"warning-span"},[_vm._v(" "+_vm._s(_vm.showWarningText)+" ")]):_vm._e()],1):_vm._e(),(_vm.isViewCopy && _vm.data.properties.filed_content != 'Hide')?_c('p',{style:(_vm.fromRepeatable ? '' : _vm.getStyle)},[(
                _vm.form[_vm.data.key] &&
                _vm.form[_vm.data.key] !== 'ALL' &&
                _vm.form[_vm.data.key] &&
                _vm.form[_vm.data.key + '/name']
              )?[_vm._v(_vm._s(_vm.form[_vm.data.key + "/name"]))]:(_vm.data.show_all_option && _vm.form[_vm.data.key] == 'ALL')?[_vm._v(" "+_vm._s("All")+" ")]:[_vm._v("-")]],2):_vm._e()]),(_vm.data.allow_quick_add && !_vm.isViewCopy && _vm.fromRepeatable)?_c('el-col',{attrs:{"span":4}},[_c('quickEntityAddWidget',{attrs:{"currentEntity":_vm.currentEntity,"parentForm":_vm.form,"parentFields":_vm.fieldsData,"fromRepeatable":_vm.fromRepeatable},on:{"onNewEntity":_vm.setQuickAddEntityDataAsValue}})],1):_vm._e()],1)]):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{staticStyle:{"display":"flex"},style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq
              ? 4
              : _vm.data.description
              ? _vm.data.allow_quick_add
                ? 20
                : 20
              : _vm.data.allow_quick_add
              ? 20
              : 24}},[_c('div',{staticClass:"d-flex"},[(
                _vm.hasLabel &&
                _vm.data.properties.filed_content != 'Hide' &&
                !_vm.data.detailed_view
              )?_c('label',{staticStyle:{"margin-right":"5px"},style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(
                _vm.data.validations.required &&
                _vm.hasLabel &&
                !_vm.data.detailed_view &&
                !_vm.isView
              )?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-right":"5px"}},[_vm._v("*")]):_vm._e(),_c('span',{staticStyle:{"margin-right":"5px"}},[(_vm.data.description && !_vm.fromRepeatable && _vm.hasLabel)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),(
              _vm.data.allow_quick_add &&
              !_vm.isViewCopy &&
              !_vm.fromRepeatable &&
              (_vm.isFromDocument || !_vm.checkReadonly())
            )?_c('span',{staticStyle:{"margin":"5px"}},[_c('quickEntityAddWidget',{attrs:{"currentEntity":_vm.currentEntity,"parentForm":_vm.form,"parentFields":_vm.fieldsData},on:{"onNewEntity":_vm.setQuickAddEntityDataAsValue}})],1):_vm._e(),_c('span',[(_vm.data.search_mode_enabled && _vm.form[_vm.data.key])?_c('i',{staticClass:"el-icon-edit",staticStyle:{"cursor":"pointer","margin-right":"5px"},on:{"click":function($event){return _vm.handleEditSearchMode()}}}):_vm._e()])]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e()])],1),_c('el-row',[_c('el-col',{attrs:{"span":_vm.showLabel && _vm.isDefalutPosq ? (_vm.data.allow_quick_add ? 24 : 23) : 23}},[(
              !_vm.isViewCopy &&
              _vm.data.properties.filed_content != 'Hide' &&
              !_vm.refresh
            )?_c('div',{style:(_vm.getStyle)},[(_vm.data.detailed_view)?_c('div',[(_vm.data.detailed_view)?_c('el-button',{attrs:{"type":"text","underline":false,"disabled":_vm.checkReadonly(),"loading":_vm.optionsLoading},on:{"click":_vm.openDetailedSelect}},[_vm._v(" "+_vm._s(_vm.label || _vm.data.label)+" ")]):_vm._e(),(
                  _vm.form[_vm.data.key] && _vm.form[_vm.data.key] && _vm.form[_vm.data.key + '/name']
                )?_c('div',[_vm._v(" "+_vm._s(_vm.getSplitedLabel(_vm.form[_vm.data.key + "/name"]))+" ")]):_vm._e()],1):(_vm.data.show_count && _vm.data.detailed_view_count)?_c('div',[(_vm.data.detailed_view_count)?_c('el-button',{attrs:{"type":"text","underline":false,"disabled":_vm.checkReadonly(),"loading":_vm.optionsLoading},on:{"click":_vm.openDetailedCount}},[(_vm.checkCurrency()?.currency)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.form[_vm.data.key],_vm.checkCurrency().currency))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key] || 0)+" ")])]):_vm._e()],1):(_vm.data.show_count && !_vm.data.detailed_view_count)?_c('div',[(_vm.checkCurrency())?_c('span',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.form[_vm.data.key],_vm.checkCurrency().currency))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key] || 0)+" ")])]):(
                _vm.optionsLoading && _vm.form[_vm.data.key] && _vm.form[_vm.data.key].length
              )?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.optionsLoading),expression:"optionsLoading"}],style:(`height: ${_vm.data.height} px;`)},[_vm._v(" "+_vm._s(_vm.form[_vm.data.key + "/name"] && !_vm.form[_vm.data.key + "/name"].includes("Data ") ? _vm.form[_vm.data.key + "/name"] : "")+" ")]):_c('el-row',[(!_vm.data.detailed_view_count && !_vm.data.detailed_view)?_c('el-col',{attrs:{"span":_vm.data.properties.qr_code_scanning ? 22 : 24}},[(_vm.data.search_mode_enabled && !_vm.form[_vm.data.key])?_c('el-form',{nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEntityExists.apply(null, arguments)}}},[_c('el-form-item',[_c('el-input',{attrs:{"disabled":_vm.checkIsSearchDisabled},model:{value:(_vm.data_search_input),callback:function ($$v) {_vm.data_search_input=$$v},expression:"data_search_input"}},[(
                          !(
                            _vm.data.customizeSearchButton &&
                            _vm.data.SearchButtonConfigs
                          )
                        )?_c('el-button',{attrs:{"slot":"append","disabled":!_vm.data_search_input,"icon":"el-icon-search"},on:{"click":_vm.checkEntityExists},slot:"append"}):_vm._e()],1),(
                        _vm.data.customizeSearchButton && _vm.data.SearchButtonConfigs
                      )?_c('el-button',{style:(_vm.buttonStyle),attrs:{"disabled":!_vm.data_search_input},on:{"click":_vm.checkEntityExists}},[_vm._v(_vm._s(_vm.data.SearchButtonConfigs.text))]):_vm._e()],1)],1):_c('el-select',{directives:[{name:"cancel-read-only",rawName:"v-cancel-read-only"}],class:_vm.highlight ? 'highlight' : '',style:(_vm.getStyle(_vm.data.properties.qr_code_scanning)),attrs:{"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"clearable":"","placeholder":_vm.data.placeholder || _vm.data.label,"collapse-tags":"","value-key":'entity_data_id',"filterable":"","suffix-icon":_vm.data.properties.el_icon,"loading":_vm.optionsLoading,"remote-method":_vm.searchEntityData,"required":_vm.data.validations.required,"remote":"","loading-text":"Loading...","multiple":_vm.data.allow_multiple || false},on:{"change":function($event){return _vm.checkEntityFields(_vm.form[_vm.data.key], true)},"clear":_vm.handleClear},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}},[(!_vm.data.is_entity_field)?[(_vm.data.show_all_option)?_c('el-option',{attrs:{"value":"ALL","label":"All"}}):_vm._e(),_vm._l((_vm.entitiesData),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}})}),(!_vm.isSearching && _vm.hasMore)?_c('el-option',{attrs:{"value":"LOADMORE","label":"Load more..."}}):_vm._e()]:_vm._e(),(
                      _vm.data.is_entity_field &&
                      _vm.form[_vm.data.key + '/default'] &&
                      _vm.form[_vm.data.key + '/default'].length
                    )?_vm._l((_vm.entitiesData.filter(
                        (e) =>
                          _vm.form[_vm.data.key + '/default'].indexOf(e._id) != -1
                      )),function(temp,i){return _c('el-option',{key:i,attrs:{"value":temp._id,"label":_vm.getLabel(temp, i)}})}):_vm._e()],2)],1):_vm._e(),(_vm.data.properties.qr_code_scanning && !_vm.isView)?_c('el-col',{attrs:{"span":_vm.data.properties.qr_code_scanning ? 2 : 24}},[_c('span',{staticClass:"qrCodeBlock"},[(_vm.data.properties.qr_code_scanning)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.qrDescription}},[_c('i',{staticClass:"el-icon-camera size-2em",attrs:{"slot":"reference"},on:{"click":_vm.enableQRReader},slot:"reference"})]):_vm._e()],1)]):_vm._e()],1),(_vm.showWarningText)?_c('span',{staticClass:"warning-span"},[_vm._v(" "+_vm._s(_vm.showWarningText)+" ")]):_vm._e()],1):_vm._e(),(_vm.isViewCopy && _vm.data.properties.filed_content != 'Hide')?_c('p',{staticStyle:{"font-weight":"normal"},style:(_vm.fromRepeatable ? '' : _vm.getStyle)},[(_vm.data.show_count)?[_c('span',[_vm._v(_vm._s(_vm.form[_vm.data.key] || 0))])]:(
                _vm.form[_vm.data.key] &&
                _vm.form[_vm.data.key] !== 'ALL' &&
                _vm.form[_vm.data.key + '/name']
              )?[_vm._v(_vm._s(_vm.form[_vm.data.key + "/name"]))]:(_vm.data.show_all_option && _vm.form[_vm.data.key] == 'ALL')?[_vm._v(" "+_vm._s("All")+" ")]:[_vm._v("-")]],2):_vm._e()]),(_vm.data.allow_quick_add && !_vm.isViewCopy && _vm.fromRepeatable)?_c('el-col',{attrs:{"span":4}},[_c('quickEntityAddWidget',{attrs:{"currentEntity":_vm.currentEntity,"parentForm":_vm.form,"parentFields":_vm.fieldsData,"fromRepeatable":_vm.fromRepeatable},on:{"onNewEntity":_vm.setQuickAddEntityDataAsValue}})],1):_vm._e()],1)],1),_c('dialog-component',{staticClass:"DetailedView",attrs:{"visible":_vm.entityDetailedViewModal,"title":'Select ' +
        (_vm.currentEntity && _vm.currentEntity.name ? _vm.currentEntity.name : ''),"fullscreen":true,"containerMaxHeight":'80vh',"containerWidth":'50%',"isShowFooter":false},on:{"before-close":_vm.resetEntityDetailedView}},[_c('div',[_c('EntityFieldDetailedView',{attrs:{"currentEntity":_vm.currentEntity,"data":_vm.entitiesData,"fieldsData":_vm.fieldsData,"field":_vm.data,"form":_vm.form,"resetViewType":_vm.resetView,"isFromEntityExecutePopUp":true},on:{"close":_vm.resetEntityDetailedView,"rows-selected":_vm.checkEntityFields,"data-table-selected":_vm.updateEntityDataTable}})],1)]),_c('dialog-component',{staticClass:"custom-dialog",attrs:{"visible":_vm.showDetailedCount,"title":this.data.label,"containerMinHeight":'50vh',"containerWidth":'50w',"isShowFooter":false},on:{"before-close":_vm.closeDetailedCount}},[_c('div',[(_vm.showDetailedCount)?_c('CustomDashboardTable',{attrs:{"component":_vm.selectedComponent,"entity_id":_vm.entityId,"customFilers":_vm.appliedFilters,"hide_options":true,"hide_export":true,"hide_expend":true,"hideAllActions":true,"externalLimit":10,"externalPage":1,"parent_entityId":_vm.data.parent_entityId,"parent_entityDataId":_vm.data.parent_entityDataId}}):_vm._e()],1)]),_c('dialog-component',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.qrLoader),expression:"qrLoader"}],attrs:{"title":_vm.modalTitle,"visible":_vm.showQrReader,"containerWidth":'50vw',"containerMaxHeight":'70vh'},on:{"before-close":_vm.cancelQRPopup}},[(_vm.showQrReader)?_c('QRReader',{attrs:{"data":_vm.data,"form":_vm.form,"isFromTemplates":true},on:{"before-close":_vm.cancelQRPopup}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }